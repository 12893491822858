<template>
    <div class="container content-space-1">
        <h2 v-if="$validateText(slice.primary.h2)">
            {{ $prismic.asText(slice.primary.h2) }}
        </h2>
        <div 
            v-if="$validateText(slice.primary.teaser)"
            class="rich-text text-dark mb-4"
            v-html="$prismic.asHtml(slice.primary.teaser)" 
        />
        <div class="d-flex flex-column gap-7 small">
            <table-calculation-item 
                v-for="(lenderItem, index) in lenders" 
                :key="index"
                :lender="lenderItem.lender" 
                :amount="loanAmount" 
                :periods="periods"
                :interest-type="lenderItem.interestType"
            />        
        </div>
    </div>
</template>

<script>
import TableCalculationItem from '@/components/TableCalculationItem.vue';
export default {
    components: {
        TableCalculationItem
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        lenders() {
            return this.slice.items
                .filter(item => item.lender.id)
                .map(item => {
                    return {
                        lender: this.$store.state.lenders[item.lender.id],
                        interestType: item.interest_type ?? 'min_interest'
                    };
                });
        },
        periods() {
            return {
                firstPeriod: this.slice.primary.period_1 || 12,
                secondPeriod: this.slice.primary.period_2 || 48,
                thirdPeriod: this.slice.primary.period_3 || 144,
            };
        },
        loanAmount() {
            return this.slice.primary.table_calculation_amount || 5000;
        }
    },
};
</script>